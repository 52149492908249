html {
  scroll-behavior: smooth;
}

:root {
  --primary-color: #006b00;
  --secondary-color: #006b00;
  --hover-color: #006b00;
}

/* hide scrollbar */
/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* navigation menu */
#burgerMenu {
  display: none !important;
}

#nav-menu {
  max-width: 430px;
  padding: 0px 100px;
  box-shadow: 0px 0px 13px #0000004d;
}

#inner-nav {
  margin-top: 50px;
}

.nav-item-text {
  color: #999999;
  font-size: 18px;
  transition: color 0.8s ease;
}

.nav-item-text.active {
  /* color: var(--primary-color); */
  color: var(--primary-color);
}

.nav-item:hover .nav-item-text {
  color: var(--primary-color);
}

.nav-item:hover {
  text-decoration: none;
}

.active-line {
  height: 2px;
  width: 0;
  background-color: var(--primary-color);
  transition: width 0.8s ease;
}

.active-line.active {
  width: 18px;
  margin-right: 10px;
}

.values-item {
  max-width: 22%;
}

/* nav menu */
.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
  background-color: var(--primary-color) !important;
  height: 2px !important;
}

.hamburger--collapse .hamburger-inner:after {
  display: none !important;
}

.hamburger--collapse .hamburger-inner:before {
  top: -12px !important;
}

.hamburger--collapse.is-active .hamburger-inner:before {
  top: 0px !important;
}

.hamburger {
  padding: 0px !important;
}

.hamburger--collapse.is-active .hamburger-inner {
  bottom: 0px !important;
}

button:focus {
  outline: none !important;
}

/* fonts */
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
a {
  font-family: "Inter", sans-serif;
  color: #666666;
  font-weight: 400;
}

h2 {
  font-size: 60px;
}

h5 {
  font-size: 36px;
  margin: 0;
}

.content-box {
  gap: 50px;
}

.content-row {
  gap: 12px;
}

p,
a {
  font-size: 20px;
  margin: 0;
  line-height: 1.3;
}

/* general */
.container {
  min-height: 100vh;
  max-width: 1830px;
  padding: 150px 100px 250px 100px;
  position: relative;
  gap: 150px;
}

#main-container {
  left: var(--menu-width);
  width: calc(100vw - var(--menu-width));
  max-width: 100%;
}

body {
  min-height: 100vh;
}

.hidden {
  display: none !important;
}

.border-20 {
  border-radius: 20px;
}

.col {
  padding: 0;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.card {
  padding: 5px;
  width: 100%;
  background-color: white;
  gap: 15px;
  padding: 50px 25px;
  border: 5px solid var(--primary-color);
  border-radius: 15px;
}

.gap-10 {
  gap: 10px;
}

.gap-25 {
  gap: 25px;
}

.gap-35 {
  gap: 35px;
}

.gap-50 {
  gap: 50px;
}

.pt-25 {
  padding-top: 25px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.currentStudies {
  margin-bottom: 20px !important;
}

.flex-unset {
  flex: unset;
}

.stretch {
  align-items: stretch;
}

/* colours */
.white {
  color: #fff;
}

.green {
  color: var(--primary-color);
}

.light-grey {
  color: #999999;
}

.image-clip-path {
  clip-path: polygon(
    5% 0,
    95% 0,
    100% 5%,
    100% 95%,
    95% 100%,
    5% 100%,
    0 95%,
    0 5%
  );
}

/* about me */
#aboutMe .col-left {
  padding: 0px 50px 0px 0px;
}

#aboutMe .col-right {
  padding: 0px 0px 0px 50px;
}

.current-club-badge {
  height: 35px;
}

.contact-button {
  background-color: var(--primary-color);
  width: 300px;
  height: 70px;
  border-radius: 20px;
}

/* video wrapper */
.pt-20 {
  padding-top: 20px;
}

.video-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* 16:9 aspect ratio (9 divided by 16 multiplied by 100) */
  cursor: pointer;
}

.video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bio-video-items {
  top: 50%;
  left: 50%;
  z-index: 500000;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.bio-video-items.hidden {
  opacity: 0;
  display: none !important;
}

/* education */
.course-title {
  /* max-height: 50px; */
}

.education-icon {
  width: 80px;
}

.from-number {
  font-size: 40px;
}

.completed-number {
  font-size: 60px;
}

.row-gap {
  margin-bottom: 25px;
}

.education-card {
  padding: 25px !important;
  flex: 1;
}

.education-card.active {
  /*border-bottom: 5px solid transparent;*/
  border: none;
  padding: 25px !important;
}

.card-body,
.expand-row {
  padding: 25px !important;
}

.outer-card {
  border-radius: 15px;
  /*border-bottom: 5px solid transparent;*/
  border: none;
}

.outer-card.active {
  border: 5px solid var(--primary-color);
  /*border-bottom: 5px solid transparent;*/
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.expand-collapse-row {
  padding: 35px 25px 50px 25px;
  gap: 15px;
  border: 5px solid transparent;
}

.expand-collapse-row.active {
  border: none;
}

.expand-collapse-seperator {
  border-bottom: 1px solid #707070;
}

.rotate {
  transform: rotate(180deg);
}

.rotate.active {
  transform: rotate(0deg);
}

.cursor-pointer {
  cursor: pointer;
}

.box-title {
  font-size: 16px;
}

.education-title-box {
  padding: 15px 0px;
  border: #707070 1px solid;
}

.box-title.active {
  color: #fff;
}

.education-title-box.active {
  border: var(--primary-color) 1px solid;
  background-color: var(--primary-color);
}

#education-tiles {
  z-index: 250;
}

#education-tiles .card-top {
  max-width: calc(33.33% - 33.3px);
}

#education-expand-tile-row {
  z-index: 200;
}

.education-expand-tile {
  padding: 50px;
  border: 5px solid var(--primary-color);
  top: -5px;
  border-radius: 10px;
}

#card1-expanded-tile {
  border-top-left-radius: 0;
}

#card3-expanded-tile {
  border-top-right-radius: 0;
}

.outer-card-bottom-hide.active {
  height: 5px;
  background-color: white;
  z-index: 500;
  bottom: 0;
  left: 5px;
  width: calc(100% - 10px);
}

.outer-card-bottom-hide {
  height: 5px;
  z-index: 500;
  bottom: 0;
  left: 5px;
  width: calc(100% - 10px);
}

.education-card.active .card-body:hover {
  /* background-color: #006b000d; */
  background-color: color-mix(in srgb, var(--primary-color) 8%, transparent);
  cursor: pointer;
}

.card-body.active {
  /* background-color: #006b000d; */
  background-color: color-mix(in srgb, var(--primary-color) 8%, transparent);
  border-radius: 10px;
  border: 3px solid var(--primary-color);
}

.education-unit {
  background-color: #fafafa;
  padding: 25px;
  border-radius: 10px;
  border: 3px solid transparent;
}

.education-unit:hover {
  background-color: color-mix(in srgb, var(--primary-color) 8%, transparent);
}

.unit-icon {
  height: 50px;
}

.audio-icon {
  width: 80px;
}

.file-icon {
  height: 38px;
}

.attachment-image {
  height: 80px;
}

.education-attachment {
  padding: 25px;
  border: 3px solid var(--primary-color);
  border-radius: 10px;
}

.module-row {
  overflow-x: scroll;
  min-height: 50px;
  background-color: #fafafa;
  border-radius: 10px;
  padding: 5px;
  max-height: 50px;
}

.module-title {
  white-space: nowrap;
}

.module {
  padding: 5px 10px !important;
  border-radius: 10px;
  border: 2px solid transparent;
}

.module:hover {
  /* background-color: #006b0010; */
  background-color: color-mix(in srgb, var(--primary-color) 6%, transparent);
}

.module.active {
  /* background: #006b0026; */
  background: color-mix(in srgb, var(--primary-color) 8%, transparent);
  border: 2px solid var(--primary-color);
}

.attachment-inner {
  background-color: color-mix(in srgb, var(--primary-color) 8%, transparent);
  padding: 50px !important;
  border-radius: 10px;
}

.attachment-separator {
  height: 1px;
  background-color: #707070;
  padding-top: 1px;
}

.predicted {
  color: #0323b7;
}

.achieved {
  color: var(--primary-color);
}

.education-unit.active {
  background-color: color-mix(in srgb, var(--primary-color) 8%, transparent);
  border: 3px solid var(--primary-color);
}

.image-attachment {
  width: 100%;
}

/* toggle */
.list-group-item {
  border: none;
  border-bottom: 1px solid #707070;
  color: #888888;
  margin-bottom: 30px;
}

.list-group-item.active {
  background-color: transparent;
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.personal-development-toggle-text.active {
  color: var(--primary-color);
}

.list-group-item:hover,
.list-group-item-action:focus {
  background-color: transparent;
}

.list-group-item:hover .personal-development-toggle-text {
  color: var(--primary-color);
}

#personal-development-toggles {
  gap: 30px;
}

.section-collapse {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  display: block;
  /* Add this line to override Bootstrap's display: none */
}

.section-collapse.show {
  max-height: 4000px;
  /* Set an initial value higher than the expected height to accommodate expanding content */
  opacity: 1;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

/* toggle Qualifications*/

/* .list-group-item-qualifications:first-child {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
} */

.list-group-item-qualifications {
  border: none;
  border-bottom: 1px solid #707070;
  color: #888888;
  margin-bottom: 30px;
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  /* margin-bottom: -1px; */
  background-color: #fff;
  /* border: 1px solid rgba(0, 0, 0, 0.125); */
}

.list-group-item-qualifications.active {
  background-color: transparent;
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.personal-development-toggle-text-qualifications.active {
  color: var(--primary-color);
}

.list-group-item-qualifications:hover,
.list-group-item-action:focus {
  background-color: transparent;
}

.list-group-item-qualifications:hover
  .personal-development-toggle-text-qualifications {
  color: var(--primary-color);
}

#personal-development-toggles {
  gap: 30px;
}

.section-collapse-qualifications {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  display: block;
  /* Add this line to override Bootstrap's display: none */
}

.section-collapse-qualifications.show {
  max-height: 1000px;
  /* Set an initial value higher than the expected height to accommodate expanding content */
  opacity: 1;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.attachment-list,
.unit-list {
  max-height: 750px;
  overflow-y: scroll;
}

/* personal development */
.personal-development-card {
  border: 5px solid var(--primary-color);
  border-radius: 15px;
  padding: 50px;
  gap: 15px;
  justify-content: space-between;
}

.personal-development-card-info {
  gap: 15px;
  width: 49%;
}

.personal-media {
  width: 49%;
}

.personal-development-content-row {
  gap: 40px;
}

/* highlights gallery */
#main-gallery-item {
  margin-bottom: 50px;
}

/* previous club  */
.previous-club-badge {
  height: 150px;
}

#previous-clubs-title {
  margin-bottom: 50px;
}

.year-range-separator {
  width: 100%;
  height: 2px;
  background-color: var(--primary-color);
}

/* football stats */
.key {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.season {
  background-color: #65a8d1;
}

.career {
  background-color: #743bd1;
}

.expand-collapse-row-football-stats {
  padding: 0px 15px 0px 0px;
  gap: 15px;
}

.stat-box {
  max-height: 0;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  /* Add opacity transition */
  overflow: hidden;
  opacity: 0;
  /* Set initial opacity to 0 */
}

.stat-box.active {
  max-height: 500px;
  opacity: 1;
  /* Set opacity to 1 on active */
}

.season-text {
  color: #65a8d1;
}

.career-text {
  color: #743bd1;
}

/* apex charts  */
.apexcharts-xaxis-tick,
.apexcharts-gridline,
.apexcharts-grid-borders,
.apexcharts-grid-borders line {
  stroke: transparent;
}

.apexcharts-text {
  transform: translateY(-15px);
}

.apexcharts-datalabel-label {
  display: none !important;
}

/* #transferable-values .apexcharts-inner {
  transform: scale(1.4) translate(0px, -5px);
} */

/* 4 corner model */
.fcm-number {
  /* height: 50px; */
  width: 100%;
  border-radius: 0px;
  color: white;
}

.time-number {
  height: 50px;
  width: 100%;
  border-radius: 0px;
  color: var(--primary-color);
  border-bottom: 3px solid var(--primary-color);
}

.red.fcm-number {
  color: #ff0000;
  border-bottom: 2px solid #ff0000;
}

.orange.fcm-number {
  color: #ff8c00;
  border-bottom: 2px solid #ff8c00;
}

.green.fcm-number {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}

/* tutor comment */
.tutor-comment-card {
  border: 5px solid var(--primary-color);
  border-radius: 15px;
  padding: 50px;
}

/* references */
.reference-box {
  border: 5px solid var(--primary-color);
  border-radius: 15px;
  padding: 10px 50px;
}

/* popup */
#popup {
  width: 95%;
  max-width: 600px;
  border-radius: 15px;
  border: 5px solid var(--primary-color);
  box-shadow: 0px 0px 13px #0000004d;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  padding: 50px;
  z-index: 50000000;
  background-color: white;
  gap: 80px;
  transition: all 0.5s ease;
  display: none !important;
}

#popup.active {
  opacity: 1;
  transition: all 0.5s ease;
  display: flex !important;
}

.contact-details {
  gap: 50px;
}

.contact-row {
  gap: 20px;
}

.phone-icon,
.contact-icon.phone-icon {
  width: 30px;
  height: 30px;
}

.contact-icon {
  width: 30px;
}

.contact-link {
  padding: 10px;
  border-radius: 100%;
}

.contact-link:hover {
  background-color: #006b0020;
}

.email-icon,
.contact-icon.email-icon {
  width: 30px;
  height: 22.5px;
}

#popup-close {
  top: 60px;
  right: 50px;
  cursor: pointer;
}

.close-icon {
  width: 20px;
}

#contact-me-btn:hover {
  /* background-color: #005200; */
  background-color: color-mix(in srgb, var(--primary-color) 80%, black);
}

.lighten {
  opacity: 50%;
}

/* reflowing */

@media screen and (max-width: 2000px) {
  #nav-menu {
    padding: 0px 75px;
    overflow-y: scroll;
    max-width: 380px;
  }

  #inner-nav.gap-50,
  #transferable-values .gap-50 {
    gap: 25px;
  }

  #menu-logo,
  #menu-image {
    max-width: 230px;
  }

  .card-body {
    padding: 10px !important;
  }

  .attachment-image {
    height: 60px;
  }

  .audio-icon {
    width: 60px;
  }

  #previous-clubs h5 {
    font-size: 24px;
  }
}

@media screen and (max-width: 1830px) {
  .container {
    max-width: 100%;
    padding: 100px 50px 150px 50px;
  }

  p,
  a {
    font-size: 18px;
  }

  #education-tiles.gap-50 {
    gap: 25px;
  }

  .box-title {
    font-size: 14px;
  }
}

@media screen and (max-width: 1600px) {
  #nav-menu {
    padding: 0px 40px;
    overflow-y: scroll;
    max-width: 240px;
  }

  #menu-logo,
  #menu-image {
    max-width: 150px;
  }

  #inner-nav.gap-50,
  #transferable-values .gap-50 {
    gap: 18px;
  }

  .nav-item-text,
  p {
    font-size: 16px;
  }

  h2 {
    font-size: 48px;
  }

  h5 {
    font-size: 20px;
  }

  .gap-50 {
    gap: 25px;
  }

  .container {
    gap: 100px;
  }

  .content-box {
    gap: 25px;
  }

  #previous-clubs-title {
    margin-bottom: 25px;
  }

  .apexcharts-text {
    font-size: 36px !important;
    transform: translateY(-20px);
  }

  .apexcharts-datalabel {
    font-size: 16px !important;
    transform: translateY(-10px);
  }

  .tutor-comment-card,
  .personal-development-card,
  .card {
    padding: 25px;
  }

  .education-card,
  .education-card.active {
    padding: 15px !important;
  }

  .education-icon {
    width: 50px;
  }

  .row-gap {
    margin-bottom: 25px;
  }

  .box-title {
    font-size: 12px;
  }

  .unit-icon {
    height: 40px;
  }

  .attachment-image {
    height: 45px;
  }
}

@media screen and (max-width: 1370px) {
  .bio-video-items {
    display: none !important;
  }
}

@media screen and (max-width: 1280px) {
  .nav-item-text,
  p {
    font-size: 14px;
  }

  .container {
    padding: 100px 40px 100px 40px;
  }

  #inner-nav.gap-50,
  #transferable-values .gap-50 {
    gap: 14px;
  }

  #nav-menu {
    padding: 0px 30px;
    overflow-y: scroll;
    max-width: 300px;
    z-index: 100000;
    background-color: #fff;
    transform: translateX(-100%);
    transition: all 0.5s ease;
  }

  #nav-menu.show {
    transform: translateX(0);
    transition: all 0.5s ease;
  }

  #main-container {
    left: auto;
    width: unset;
  }

  .completed-number {
    font-size: 50px;
  }

  .from-number {
    font-size: 30px;
  }

  #education .gap-25 {
    gap: 10px;
  }

  .box-title {
    font-size: 10px;
  }

  .expand-collapse-row {
    padding: 20px 20px 30px 20px;
  }

  .tutor-comment-card,
  .personal-development-card,
  .card {
    padding: 15px;
  }

  .previous-club-badge {
    height: 120px;
  }

  #previous-clubs h5 {
    font-size: 20px;
  }

  .play-button {
    width: 60px;
  }

  /* #transferable-values .apexcharts-inner {
    transform: scale(1.2) translate(15px, -10px);
  } */

  #burgerMenu {
    display: flex !important;
    background-color: #fff;
    z-index: 50000;
    padding: 20px 40px;
    box-shadow: 0px 0px 13px #0000004d;
  }
}

@media screen and (max-width: 1025px) {
  .nav-item-text,
  p {
    font-size: 12px;
  }

  .previous-club-badge {
    height: 100px;
  }

  #previous-clubs h5 {
    font-size: 18px;
  }

  .apexcharts-datalabel {
    font-size: 14px !important;
    transform: translate(45px, -12px);
  }

  .play-button {
    width: 50px;
  }

  .completed-number {
    font-size: 40px;
  }

  .from-number {
    font-size: 22px;
  }

  .unit-icon {
    height: 30px;
  }

  .education-unit {
    padding: 15px;
  }

  .attachment-inner {
    padding: 25px !important;
  }

  .attachment-image {
    height: 40px;
  }

  .file-icon {
    height: 25px;
  }

  .audio-icon {
    width: 40px;
  }
}

@media screen and (max-width: 769px) {
  #nameBreak {
    display: none;
  }

  #burgerMenu {
    display: none !important;
  }

  .container {
    padding: 20px 20px 40px 20px;
    gap: 50px;
  }

  #education-tiles.flex-nowrap {
    flex-wrap: wrap !important;
  }

  .col-12 {
    max-width: 100% !important;
  }

  .outer-card.active {
    border: none;
  }

  .card.education-card {
    border: none;
  }

  .expand-collapse-row {
    padding: 0px 00px 0px 20px;
    min-height: 15px;
    width: auto !important;
    position: absolute;
    top: 50%;
    right: 5px;
  }

  .expand-collapse-row.active {
    border: 5px solid transparent;
  }

  .expand-collapse-row .expand-collapse-seperator {
    display: none;
  }

  .unit-info-row-inner {
    flex-direction: row !important;
    display: flex;
    gap: 10px;
  }

  .completed-number,
  .from-number {
    font-size: 14px;
  }

  .nav-item-text,
  p {
    font-size: 14px;
  }

  .from-div {
    flex-direction: row;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .complete-number-div {
    padding: 0 !important;
  }

  .number-section {
    gap: 10px;
    align-items: center;
  }

  .unit-info-row {
    gap: 10px;
  }

  .education-title-box.active {
    background-color: unset;
    border: unset;
  }

  .education-card,
  .education-card.active {
    padding: 0px !important;
  }

  .box-title {
    font-size: 16px;
  }

  .box-title.active {
    color: var(--primary-color);
  }

  .education-title-box {
    padding: 0;
    border: none;
    justify-content: flex-start !important;
  }

  .row-gap {
    margin-bottom: 15px;
  }

  #education-tiles {
    gap: 15px !important;
  }

  .education-expand-tile {
    border: 5px solid transparent;
  }

  .card-body.active {
    border: none;
    background-color: transparent;
  }

  .card-top {
    padding-bottom: 10px !important;
    border-bottom: 1px solid #666666;
  }

  .education-expand-tile {
    padding: 15px 0px;
    border: none;
  }

  .education-attachment {
    padding: 10px;
  }

  .no-scroll {
    overflow: hidden;
  }

  .unit-attachment-row {
    flex-direction: column !important;
  }

  .unit-list {
    width: 100%;
    flex-direction: row !important;
    overflow-x: scroll;
    position: relative;
    min-width: 100%;
    border-radius: 10px;
  }

  .unit-title {
    font-size: 16px;
    color: var(--primary-color);
  }

  .unit-icon,
  .unit-icon-div {
    display: none !important;
  }

  .education-unit {
    padding: 0px 10px;
    gap: 0px;
    width: fit-content !important;
    background-color: transparent;
  }

  .education-unit.active {
    background-color: #fff;
    border: none;
  }

  .unit-title-div {
    width: auto !important;
    max-width: fit-content !important;
    flex: unset;
  }

  .unit-list {
    padding: 10px !important;
    /* background-color: #006b0010; */
    background-color: color-mix(in srgb, var(--primary-color) 8%, transparent);
  }

  .attachment-div {
    width: 100% !important;
    max-width: 100% !important;
  }

  .education-attachment {
    border: none;
  }

  .attachment-inner {
    background-color: transparent;
    padding: 0px 0px 100px !important;
  }

  #menu-logo,
  #menu-image {
    display: none;
  }

  #nav-menu {
    padding: 0px 10px;
    max-width: 100% !important;
    position: fixed !important;
    top: 0;
    transform: none;
    height: 70px !important;
    overflow-y: hidden;
  }

  /*#nav-menu.fixed{
  position:fixed !important;
  bottom:unset !important;
  top: 0 !important;
}*/

  #inner-nav {
    flex-direction: row !important;
    margin-top: 0px;
    justify-content: unset !important;
    width: 100%;
    min-width: fit-content;
    z-index: 10000000;
    flex-wrap: nowrap;
  }

  .nav-item-outer {
    width: max-content;
  }

  #menu-outer {
    display: flex !important;
    align-items: center;
  }

  #inner-nav.gap-50 {
    gap: 20px;
  }

  .active-line {
    display: none;
  }

  .nav-item {
    display: flex;
    flex: unset;
    width: 100% !important;
    min-width: fit-content;
  }

  .nav-item-text {
    padding: 5px 10px;
    border-radius: 5px;
  }

  .nav-item-text.active {
    background-color: #f0f0f0;
  }

  .gap-50,
  #education .gap-25 {
    gap: 15px;
  }

  .interest-row,
  .video-row {
    flex-wrap: wrap !important;
  }

  .interest-tile,
  .highlight-video,
  .main-video-info,
  .bio-video-container {
    width: 100% !important;
    max-width: 100% !important;
  }

  .bio-video-container {
    flex: unset;
  }

  .card {
    min-width: 100%;
  }

  #main-gallery-item {
    margin-bottom: 15px;
  }

  #cvIntro {
    font-size: 14px;
  }

  #name {
    font-size: 40px;
  }

  .contact-button {
    height: auto;
    width: auto;
    padding: 20px 60px;
  }

  .content-box {
    gap: 15px;
  }

  #aboutMe .col-right {
    padding: 0px 0px 0px 25px;
  }

  #aboutMe .col-left {
    padding: 0px 25px 0px 0px;
  }

  .personal-development-content-row {
    gap: 20px;
  }

  .previous-club-badge {
    height: 80px;
  }

  #previous-clubs h5 {
    font-size: 14px;
  }

  h2 {
    font-size: 42px;
  }

  .football-stats-charts {
    flex-direction: column !important;
  }

  .chart-row {
    max-width: 100%;
    width: 100%;
  }

  .apexcharts-datalabel {
    font-size: 14px !important;
    transform: translate(10px, -12px);
  }

  .reference-inner {
    flex-direction: column !important;
  }

  #tutor-comment,
  #reference-list {
    max-width: 100%;
    width: 100%;
  }

  .agent-inner {
    flex-wrap: wrap;
  }

  .agent-row {
    width: 100%;
    max-width: 100%;
    flex: unset;
  }

  .card,
  .personal-development-card {
    border-width: 3px;
  }

  .personal-development-card {
    flex-direction: column;
  }

  .personal-development-card-info,
  .personal-media {
    width: 100%;
  }

  .skills-row,
  .values-row {
    flex-wrap: wrap !important;
  }

  .skills-box,
  .physical-box {
    max-width: 31%;
  }

  .values-item {
    max-width: 45%;
  }

  #transferable-values .gap-50 {
    gap: 24px;
  }

  .attachment-list,
  .unit-list {
    max-height: 500px;
    overflow-y: scroll;
  }

  .unit-list {
    overflow-y: unset !important;
    overflow-x: scroll;
  }

  .unit-title {
    font-size: 12px;
    white-space: nowrap;
  }

  body {
    orientation: portrait;
  }
}

@media screen and (max-width: 500px) {
  #popup-close {
    top: 48px;
    right: 50px;
    cursor: pointer;
  }

  #popup {
    gap: 40px;
  }

  .contact-details {
    gap: 25px;
  }

  .close-icon {
    width: 15px;
  }

  .container {
    gap: 30px;
  }

  #name {
    font-size: 24px;
  }

  #aboutMe {
    flex-direction: column-reverse !important;
    align-items: center;
  }

  #aboutMe .col-right,
  #aboutMe .col-left {
    padding: 0;
  }

  .stat-row,
  .club-row {
    flex-direction: column !important;
  }

  .stat-item,
  .club-item {
    width: 100%;
  }

  .club-row {
    gap: 40px;
  }

  .club-item {
    gap: 20px;
  }

  .skills-box,
  .physical-box {
    max-width: 45%;
  }

  .values-item {
    max-width: 45%;
  }

  h5 {
    font-size: 18px;
  }

  h2 {
    font-size: 24px;
  }

  .about-me-info {
    align-items: start !important;
    margin-top: 30px;
  }

  #name,
  #cvIntro {
    text-align: left !important;
  }

  .reference-box {
    flex-wrap: wrap !important;
    padding: 20px !important;
  }

  .tutor-comment-card {
    padding: 20px !important;
  }

  .reference-box div {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
  }

  .apexcharts-text {
    font-size: 26px !important;
    transform: translateY(-20px);
  }

  /* #transferable-values .apexcharts-inner {
    transform: scale(0.9) translate(0, 0);
  } */

  .expand-collapse-text {
    display: none;
  }

  .expand-collapse-row {
    top: 20%;
  }

  .comment-main {
    width: 100% !important;
    max-width: 100% !important;
    flex: unset;
  }

  .unit-title {
    font-size: 12px;
  }

  .attachment-list,
  .unit-list {
    max-height: 400px;
    overflow-y: scroll;
  }

  .bio-video-items {
    display: none;
  }

  .attachment-row {
    flex-direction: column !important;
  }

  .unit-icon-div {
    display: none;
  }

  .attachment-image {
    height: 80px;
  }

  #profile-image {
    max-width: 100%;
    object-fit: cover;
    aspect-ratio: 1;
    object-position: center;
  }

  /* .module-icon {
    width: 20px;
  } */
  .contact-button-row,
  #contact-me-btn {
    width: 100% !important;
  }

  .list-group-item-miscattributes {
    border: none;
    border-bottom: 1px solid #707070;
    color: #888888;
    margin-bottom: 30px;
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    /* margin-bottom: -1px; */
    background-color: #fff;
    /* border: 1px solid rgba(0, 0, 0, 0.125); */
  }

  .list-group-item-miscattributes.active {
    background-color: transparent;
    color: var(--primary-color);
    border-color: var(--primary-color);
  }

  .personal-development-toggle-text-miscattributes.active {
    color: var(--primary-color);
  }

  .list-group-item-miscattributes:hover,
  .list-group-item-action:focus {
    background-color: transparent;
  }

  .list-group-item-miscattributes:hover
    .personal-development-toggle-text-miscattributes {
    color: var(--primary-color);
  }

  #personal-development-toggles {
    gap: 30px;
  }

  .section-collapse-miscattributes {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.5s ease, opacity 0.5s ease;
    display: block;
    /* Add this line to override Bootstrap's display: none */
  }

  .section-collapse-miscattributes.show {
    max-height: 1000px;
    /* Set an initial value higher than the expected height to accommodate expanding content */
    opacity: 1;
    transition: max-height 0.5s ease, opacity 0.5s ease;
  }
}

.mediaCards {
  justify-content: space-between;
  align-items: center;
  row-gap: 15px;
}

.mediaCard {
  width: 48%;
}

.image-media-div {
  height: 100%;
  height: 200px;
}

.image-media {
  height: 100%;
}

.modal-left-button {
  left: 30px;
  top: 50%;
  z-index: 99;
}

.modal-right-button {
  right: 30px;
  top: 50%;
  z-index: 99;
}

.modal-left-button button,
.modal-right-button button {
  background-color: #fff;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.file-media-div {
  border: 2px solid var(--primary-color);
  padding: 50px;
  border-radius: 12px;
  text-align: center;
  height: 200px;
}

.card__progress progress {
  width: 100%;
  height: 8px;
  border-radius: 100px;
  transition: width 0.5s ease-in-out;
}

.card__progress progress::-webkit-progress-bar {
  background-color: #00000030;
  border-radius: 100px;
}

.card__progress progress::-webkit-progress-value {
  background-color: var(--primary-color);
  border-radius: 100px;
  transition: width 0.5s ease-in-out;
}

/* .workExperienceProgress {
  padding: 0 40px;
} */

.card__indicator {
  font-weight: 500;
  font-size: 14px;
  text-align: end;
}

.card__indicator-amount {
  font-size: 18px;
}
