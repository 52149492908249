.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6); /* Background opacity */
  z-index: 1049;
}

.modal-dialog {
  position: relative;
  z-index: 1051;
}

.modal-content {
  background: white;
  border-radius: 8px;
  overflow: hidden;
}

.image-media {
  display: block;
  max-width: 100%;
  /* height: auto; */
}
