#containerSecurityForm {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("https://routeresourcegroup.blob.core.windows.net/image/image_cv-modified.png");
  background-repeat: no-repeat;
  background-size: cover;
}

#containerSecurityForm::before {
  background-color: rgba(0, 0, 0, 0.37);
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}

.containerSecurityForm {
  max-width: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.containerSecurityForm h2 {
  text-align: center;
  margin-bottom: 20px;
}

.containerSecurityForm label {
  display: block;
  margin-bottom: 8px;
}

.containerSecurityForm input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.containerSecurityForm button {
  background-color: #4caf50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

.containerSecurityForm button:hover {
  background-color: #45a049;
}

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
} */

.wrapper {
  position: relative;
  max-width: 430px;
  width: 100%;
  background: #fff;
  padding: 34px;
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.wrap {
  flex-direction: column;
  /* row-gap: 27px; */
}

.wrapper h2 {
  position: relative;
  font-size: 22px;
  font-weight: 600;
  color: #333;
  text-align: center;
}

.wrapper form {
  margin-top: 30px;
}

.wrapper form .input-box {
  height: 52px;
  margin: 18px 0;
  margin-bottom: 10px;
  width: 100%;
}

form .input-box input {
  height: 100%;
  width: 100%;
  outline: none;
  padding: 0 15px;
  font-size: 17px;
  font-weight: 400;
  color: #333;
  border: 1.5px solid #c7bebe;
  border-bottom-width: 2.5px;
  border-radius: 6px;
  transition: all 0.3s ease;
}

form h3 {
  color: #707070;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
}

.input-box.button input {
  color: #fff;
  letter-spacing: 1px;
  border: none;
  background: #006300cf;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
}

.input-box.button input:hover {
  background: #3f8f42;
}

form .text h3 {
  color: #333;
  width: 100%;
  text-align: center;
}

form .text h3 a {
  color: #4070f4;
  text-decoration: none;
}

.buttonload {
  background-color: #006300cf;
  /* Green background */
  border: none;
  /* Remove borders */
  color: white;
  /* White text */
  padding: 12px 24px;
  /* Some padding */
  font-size: 16px;
  /* Set a font-size */
  display: grid;
  place-items: center;
  width: 100%;
  border-radius: 6px;
  margin-top: 20px;
}

.loader {
  border: 2px solid #fff;
  border-radius: 50%;
  border-top: 2px solid transparent;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
