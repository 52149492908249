.logoImg {
  width: 40%;
}

.ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  background: transparent;
  /* border: 3px solid #3c3c3c; */
  /* border: 3px solid white; */
  border-radius: 50%;
  text-align: center;
  line-height: 150px;
  font-family: sans-serif;
  font-size: 20px;
  color: #006b01;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px #006b01;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); */
  display: grid;
  place-items: center;
}
.ring:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-top: 4px solid #006b01;
  border-right: 3px solid #006b01;
  border-radius: 50%;
  animation: animateC 2s linear infinite;
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}
